
$default-color: white;
$secondary-color: rgb(255, 87, 88);

@keyframes toggler_close_animation {
    from {
        left: 210px;
    }

    to {
        left: 90px;
    }
}

@keyframes toggler_open_animation {
    0% {
        left: 90px;
    }

    100% {
        left: 210px;
    }
}
select{
    border:none;
    background-color: transparent;
    &:focus{
        outline: none;
    }
}
.sidebar-custom{
    .w-50 {
        width: 84% !important;
    }
    .dropdown{
        font-weight: 600;
    }
}
button#languageDropdown {
    background: rgba(225, 229, 238, 0.7803921569);
    border: 2px solid #ccd6dd;
    padding: 10px;
    border-radius: 12px;
    margin-right: 5px;
}

.sidebar_layout {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    @media only screen and (max-width: 800px) {
        .sidebar-custom2{
            position: fixed;
            height: 100%;
            top: 0;
            left: -85px;
            z-index: 99;
            background-color: white;
            &.collapsed {
                left: 0;
            }
        }
    }
    // border: 5px solid $default-color;
    .termini-logo-text {
        width: 45%;
        height: 70px;
        vertical-align: middle;
        padding-top: 40px;
        margin-bottom: 15px;

    }

    .sidebar-custom {
        overflow: auto;
        position: absolute;
        height: 100vh;
        background-color: white;
        border-right: 1px solid lightgray;
    }

    .collapse_sidebar_button {
        position: absolute;
        z-index: 9;
        top: 20px;
        i {
            color: #797a7c;
            font-size: 35px;
        }

        &.collapsed {
            animation: toggler_close_animation;
            animation-duration: 300ms;
            left: 90px;
            @media only screen and (max-width: 800px) {
                left: 85px;
         
        }
      
        }

        &:not(.collapsed) {
            animation: toggler_open_animation;
            animation-duration: 300ms;
            left: 210px;

            @media only screen and (max-width: 800px) {
                left: 210px;
                z-index: 999;
         
        }
        }
    }
    .collapse_sidebar_button2 {
        position: absolute;
        z-index: 9;
        top: 20px;
        i {
            color: #797a7c;
            font-size: 35px;
        }

        &.collapsed {
            animation: toggler_close_animation;
            animation-duration: 300ms;
            left: 90px;
            @media only screen and (max-width: 800px) {
                left: 25px;
         
        }
      
        }

        &:not(.collapsed) {
            animation: toggler_open_animation;
            animation-duration: 300ms;
            left: 210px;

            @media only screen and (max-width: 800px) {
                left: 210px;
                z-index: 999;
         
        }
        }
    }

    // .border-top-right {
    //     position: fixed;
    //     top: 0;
    //     right: 0;
    //     height: 30px;
    //     width: 30px;
    //     background: transparent;
    //     border-radius: 12px;
    //     border-right: 5px solid $default-color;
    //     border-top: 5px solid $default-color;
    //     border-top-left-radius: 0;
    //     border-bottom-right-radius: 0;
    // }
    // .border-bottom-right {
    //     position: fixed;
    //     bottom: 0;
    //     right: 0;
    //     height: 30px;
    //     width: 30px;
    //     background: transparent;
    //     border-radius: 12px;
    //     border-right: 5px solid $default-color;
    //     border-bottom: 5px solid $default-color;
    //     border-bottom-left-radius: 0;
    //     border-top-right-radius: 0;
    // }
    .logo_layout {
        padding-left: 15px;
        padding-top: 4px;

        .logo_first_letter {
            width: 30px;
            height: 30px;
            color: black;
            font-weight: bold;
            font-size: xx-large;
            padding-right: 30px;
        }

        .logo_other_letters {
            height: 30px;
            display: flex;
            align-items: self-end;
            margin: 0 0 0 0px;
            font-weight: bolder;
            font-size: x-large;
        }

        .logo_last_letters {
            margin: 0;
            height: 28px;
            display: flex;
            align-items: self-end;
            font-weight: bolder;
            font-size: 20px;
            color: gray;
        }
    }

    .logo-collapsed {
        width: 280%;
        height: 245%;
        margin-top: 35%;
        margin-right: 22%;
        padding-top: 20%;
        padding-bottom: 0;
        // border-bottom: 2px solid lightgray;
    }

    .menu-icons-collapsed {

        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .menu-icons {
        margin-bottom: 6px;
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .submenu-icons {
        width: 30px;
        height: 30px;
    }

    .sidebar_footer_layout {
        z-index: 9;
        width: auto;
        height: 30px;
        position: fixed;
        bottom: 0;
        left: 0.5%;

    }
    .poweredBy {
        background-color: white;
        width: 250px;
        position: fixed;
        left: 0;
        bottom: 5px;
        padding-left: 5px;
        padding-top: 6px;
        border-top: 1px solid lightgray;
    
        .poweredBy-text {
            text-transform: lowercase;
            font-style: italic;
            font-weight: 500;
            font-size: 10px;
            display: flex;
        }
        .harrisia_logo {
            max-width: 66px;
            margin-left: 6px;
       
        }
    }
    .harrisia_icon {
        position: fixed;
        bottom: 20px;
        left: 15px;
        width: 55px;
        height: auto;
        @media only screen and (max-width: 800px) {
            left: -85px;
    }
    }
    
    .widthi {
        z-index: 999 !important;
        width: 70% !important;
    }
    
    .borderheader {
        border-bottom: 1px solid lightgray;
        padding: 0;
        margin: 0;
    }
}
.sidebarMenu{
    display: flex;
    padding: 5px;
    background-color: white;
}

hr{
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid;
    opacity: 0.20;
    width: 63%;
    margin-left: 20%;
}